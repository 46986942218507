<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-form label-position="left">
        <el-row :gutter="40">
          <el-col :span="12">
            <div style="width: 100%;padding: 1rem 0 2rem;font-weight: 600">页面参数</div>
            <el-form-item label="页面标题" >
              <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="分享描述" >
              <el-input v-model="form.share_desc"></el-input>
            </el-form-item>
            <el-form-item label="分享图片" >
              <y_upload_img v-model="form.share_pic"></y_upload_img>
            </el-form-item>
            <el-form-item label="购买协议">
              <br>
              <y_editor v-model="form.buy_agreement" eid="agreement"></y_editor>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div style="width: 100%;padding: 1rem 0 2rem;font-weight: 600">规则配置</div>
            <el-form-item label="购买金额">
              <el-input-number v-model="form.fee"></el-input-number>元,
              有效期<el-input-number v-model="form.period_of_validity"></el-input-number>天
            </el-form-item>
            <el-form-item label="免费领规则" class="flex-def">
              商城
              <el-select v-model="form.free_sys_level" placeholder="请选择">
                <el-option v-for="item in scope_option" :key="item.level" :label="item.title" :value="item.level"></el-option>
              </el-select>级别可免费领1次免单卡权益
            </el-form-item>
            <el-divider content-position="left">免单卡直推奖励权益</el-divider>
              <div class="y-desc">可享受此权益身份： <strong>免单卡会员</strong> <strong>代理商</strong> <strong>商家老板本人及员工</strong> </div>
              <div class="y-desc">次权益不因身份叠加而重复发放</div>
            <el-form-item label="奖励金额">
              <br>
              <div style="margin-bottom: .5rem">第一单奖励 <el-input-number v-model="form.rights_invite_one_award"></el-input-number> 元</div>
              <div style="margin-bottom: .5rem">
                第二单奖励 <el-input-number v-model="form.rights_invite_two_award"></el-input-number> 元
              </div>
              <div style="margin-bottom: .5rem">
                第三单奖励 <el-input-number v-model="form.rights_invite_three_award"></el-input-number> 元
              </div>
              <div>
                固定奖励 <el-input-number v-model="form.rights_invite_normal_award"></el-input-number> 元，三单后执行此金额
              </div>
              <!--              前<el-input-number v-model="form.rights_invite_limit_target"></el-input-number>单奖励-->
<!--              <el-input-number v-model="form.rights_invite_before_target_award"></el-input-number>元，-->
<!--              <br>-->
<!--              第{{form.rights_invite_limit_target}}单后奖励<el-input-number v-model="form.rights_invite_after_target_award"></el-input-number>元-->
            </el-form-item>
            <el-divider content-position="left">海报域名保护</el-divider>
            <el-form-item label="海报跳转域名">
              <el-input v-model="form.poster_domain_jump"></el-input>
              <div style="color: red" class="y-desc">注：开头加http://或https://结尾不加 ‘/’例：http://abc.com</div>
            </el-form-item>
            <el-form-item label="海报落地域名">
              <el-input v-model="form.poster_domain_landing"></el-input>
              <div style="color: red" class="y-desc">注：开头加http://或https://结尾不加 ‘/’例：http://abc.com</div>
            </el-form-item>
            <el-form-item label="海报文案">
              <el-input v-model="form.poster_copy_writing" type="textarea" autosize></el-input>
              <div class="y-desc">每行一条，回车换行</div>
            </el-form-item>
            <el-form-item label="成为会员文案">
              <el-input v-model="form.be_card_copy_writing"></el-input>
            </el-form-item>
            <el-form-item align="right">
              <el-button @click="edit" type="primary">保存</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import y_editor from "@/components/y-form/y-editor/y_editor";
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
import {levelOption} from "@/common/mod/user";
export default {
  name: "edit",
  components: {
    y_editor,
    y_upload_img,
  },
  data() {
    return {
      level_row:levelOption,
      form: {
        rights_invite_limit_target:0,
        rights_invite_before_target_award:0,
        rights_invite_after_target_award:0,

        rights_invite_one_award:0,
        rights_invite_two_award:0,
        rights_invite_three_award:0,
        rights_invite_normal_award:0,

        free_sys_level:0,
        fee:0,
        title:"",
        share_pic:"",
        share_desc:"",
        buy_agreement:"",
        period_of_validity:0,

        poster_domain_landing:"",
        poster_domain_jump:"",

        poster_copy_writing:"",
        be_card_copy_writing:"",
      }
    }
  },
  mounted() {
    this.load();
  },
  computed:{
    scope_option(){
      return this.level_row;
    }
  },
  methods: {
    scopeChange(val){
      this.form.free_sys_level = val;
    },
    load() {
      this.$u.api.shop.plugin.privilegeCard.ruleCard().then(res => {
        this.form = res;
      })
    },
    edit() {
      this.$u.api.shop.plugin.privilegeCard.ruleCardEdit(this.form).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>